@font-face {
	font-family: "KhmerOSbattambang";
	src: 
		local('Khmer OS Battambang'),
		local('Khmer OS Battambang Regular'),
		url("../fonts/KhmerOSbattambang.ttf");
	font-display: swap;
}

@font-face {
	font-family: "SegoeUI";
	src: 
		local('Khmer OS Battambang'),
		local('Khmer OS Battambang Regular'),
		url("../fonts/segoeui.ttf");
	font-display: swap;
}

/* CONTAINER */
.m-container {
	background: white;
	width: 100%;
	padding: 20px 32px;
	border-radius: 12px;
	position: relative;

	&.color {
		background-color: rgba(0, 0, 0, 0.05);
	}

	p {
		font-size: 14px;
		font-weight: bold;
		font-family: "SegoeUI", "KhmerOSbattambang";
		color: black;
		margin-left: -9px;
	}

	mat-tab-group {
		margin-top: -12px;
	}
}

@media screen and (max-width: 1440px) {
	.m-container {
		padding: 20px 24px;
	}
}

@media (max-width: 414px) {
	.m-container {
		overflow: auto;
		padding: 20px 16px;
	}
}
