@import "./m_variables.scss";

/* BUTTON */
.m-button {
	min-width: 112px !important;
	font-size: $xs-font !important;
	border-radius: 12px !important;
	height: 40px;

	.mat-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}

	.svg-icon {
		margin-top: -22px;
	}

	.normal-icon {
		margin-top: -4px;
		margin-right: 0 !important;
	}

	mat-icon {
		font-size: 22px;
		width: 18px;
		height: 18px;
		margin: 0px;
	}
}

button.m-invalid {
	border: 1px solid red !important;
}

input.m-invalid {
	border: 1px solid red !important;
}

.m-primary {
	background: $main-color-dark !important;
	color: white !important;
}

.m-normal {
	background: white !important;
	color: $main-color-dark !important;
}

/*USE THESE CLASS BELOW*/
/*-----------------------------------------------------*/

.m-button-primary {
	@extend .m-button;
	@extend .m-primary;
}

a.m-button-primary {
	&:hover {
		text-decoration: none !important;
	}
	// color: black !important;
}

.m-button-normal {
	@extend .m-button;
	@extend .m-normal;
}

.m-icon-button {
	//   padding-left: 8px !important;
	border-radius: 50% !important;

	mat-icon {
		font-size: 20px !important;
		vertical-align: middle !important;
	}
}

.m-icon-button-normal {
	@extend .m-primary;
	border-radius: 12px !important;
    min-width: 60px !important;

	mat-icon {
		color: white;
		font-size: 20px !important;
		vertical-align: middle !important;
		margin-top: -2px;
	}

	box-shadow: 0 3px 6px 0px rgb(0 0 0 / 16%);
}

.m-icon-button-primary {
	@extend .m-icon-button;
	@extend .m-primary;
	box-shadow: 0 3px 6px 0px rgb(0 0 0 / 16%);
}

.m-icon-button-secondary {
	@extend .m-icon-button;
	background: $icon-color !important;
	color: white !important;
	box-shadow: 0 3px 6px 0px rgb(0 0 0 / 16%);
}

// .m-icon-button-outlined {
// 	@extend .m-icon-button;
// 	@extend .m-outlined;
// }

.m-button-disabled {
	cursor: not-allowed;
	background: #f7f7f7 !important;
	border: 1px solid #dbdbdb !important;
	p {
		color: black !important;
	}

	// &::after {
	// 	display: inline-block !important;
	// 	margin-left: 0.255em !important;
	// 	vertical-align: 0.255em !important;
	// 	content: "";
	// 	// border-top: 0.3em solid $disabled !important;
	// 	border-top: 0.3em solid grey !important;
	// 	border-right: 0.3em solid #0000 !important;
	// 	border-bottom: 0 !important;
	// 	border-left: 0.3em solid #0000 !important;
	// }
}

.m-icon-button-edit {
	color: #de9d24 !important;
	border: 1px solid #de9d24 !important;
}

// .m-icon-button-delete {
// 	background: url("../../assets/icons/delete_icon.svg") no-repeat !important;
// 	color: #ff3c3c !important;
// 	width: 30px !important;
// 	height: 30px !important;
// }

// .m-button-create {
// 	background: url("../image/main/create-icon.svg") no-repeat !important;
// 	width: 30px !important;
// 	height: 30px !important;
// }

.invert {
	padding-right: 16px !important;
	padding-left: 30px !important;

	mat-icon {
		margin-right: 0 !important;
		margin-left: 8px !important;
	}
}

.m-icon-button-remove {
	@extend .m-icon-button;
	background: white !important;
	color: #ff3c3c !important;
	border: 1px solid #ff3c3c !important;
}

/* BUTTON SIZE */
.s-sm {
	padding: 0 16px !important;
}

.s-md {
	padding: 4px 20px !important;
}

.s-m {
	padding: 6px 20px !important;
}

.s-lg {
	padding: 8px 24px !important;
}

/* INLINE ICON BUTTON */
// .reset-icon {
// 	background: url("../../assets/icons/reset-icon.svg");
// 	background-repeat: no-repeat;
// 	height: 100%;
// }

.approve {
	color: $approve;
}

.reject {
	color: $reject;
}

.mat-icon-button.mat-primary{
	// color: #32a4ac !important;
	color: darken($color: $icon-color, $amount: 10%) !important;

	mat-icon {
		font-size: $lg-font !important;
	}
}

.mat-raised-button.mat-button-disabled:not([class*="mat-elevation-z"]) {
	background-color: #0000001f !important;
	color: #00000042 !important;
}

.mat-button.mat-button-disabled:not([class*="mat-elevation-z"]) {
	background-color: #0000001f !important;
	color: #00000042 !important;
}

.btn-container {
	.m-button-primary {
		mat-icon.set-dw {
			transform: scale(0.9);
			margin-top: -23px;
			width: 20px;
			height: 20px;
		}
	}
}

.btn-filter-group {
	align-items: flex-end;
	display: flex;
	gap: 20px;
	justify-content: flex-end;
}

.min-width-fit-content {
	min-width: fit-content !important;
}

// style mat-radio-button
.mat-radio-button {
	font-size: $xs-font !important;
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

.mat-radio-button .mat-radio-ripple {
	left: calc(50% - 17px) !important;
    top: calc(50% - 17px) !important;
    height: 30px !important;
    width: 30px !important;
}

.mat-radio-outer-circle {
	width: 16px !important;
	height: 16px !important;
}

.mat-radio-inner-circle {
	width: 16px !important;
	height: 16px !important;
}


// remove background ripple of mat-slide-toggle
// --------------------------------------------------
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
	background: transparent !important;
}

.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
	opacity: 0 !important;
}