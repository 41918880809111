/*
* font-display: swap
* temporarily show a system font while custom fonts load
*/

@font-face {
	font-family: "KhmerOSbattambang";
	src: local("Khmer OS Battambang"), local("Khmer OS Battambang Regular"), url("./assets/fonts/KhmerOSbattambang.ttf");
	font-display: swap;
}

@font-face {
	font-family: "SegoeUI";
	src: local("Khmer OS Battambang"), local("Khmer OS Battambang Regular"), url("./assets/fonts/segoeui.ttf");
	font-display: swap;
}

@import "./assets/scss/m_variables.scss";
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/scss/custom_width";
@import "./assets/scss/_custom_material.scss";
@import "./assets/scss/custom_mat_tab";
@import "./assets/scss/custom_section";
@import "./assets/scss/m_container.scss";
@import "./assets/scss/button_status_color";
@import "node_modules/progress-tracker/src/styles/progress-tracker.scss";
@import "./assets/scss/custom-progress-tracker";
@import "./assets/scss/custom_margin";
@import "./assets/scss/custom_inv_status";
@import "./assets/scss/m_file.scss";
@import "./assets/scss/m_button.scss";
@import "./assets/scss/m_form.scss";
@import "./assets/scss/m_table.scss";
@import "./assets/scss/custom_dialog";
@import "./assets/scss/custom_fonts";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	box-shadow: 0 0 0 30px white inset !important;
}

/* styling scroll bar */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	transition: 0.4s;
}

/* Track */
::-webkit-scrollbar-track {
	// box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #a3a3a3;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: $main-color-light;
}

body {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

h1,
h2,
h3 {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

.bold {
	font-weight: bold !important;
}

/*styling pagination*/
.hide-page-range {
	&.mat-paginator {
		.mat-paginator-outer-container {
			.mat-paginator-container {
				.mat-paginator-range-actions {
					.mat-paginator-range-label {
						display: none;
					}
				}
			}
		}
	}
}

.show-page-range {
	&.mat-paginator {
		.mat-paginator-outer-container {
			.mat-paginator-container {
				.mat-paginator-range-actions {
					.mat-paginator-range-label {
						display: unset;
					}
				}
			}
		}
	}
}

/*styling google map*/
google-map {
	width: 100%;
	display: block;

	.map-container {
		width: 100% !important;
		border-radius: 12px;
		overflow: hidden;
	}
}

.map-hint {
	display: flex;
	align-items: center;
	position: absolute;
	top: 34px;
	right: 45px;

	mat-icon {
		margin-top: -16px;
		margin-right: 8px;
	}

	p {
		font-weight: normal;
		font-size: small;
		color: #666666c2;
	}

	img {
		width: 18px;
		margin-top: -20px;
	}
}

/*styling tooltip*/
.mat-tooltip {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
	color: $main-color-dark !important;
	font-size: 12px !important;
	// background: rgb(26, 112, 182);
	// background: $main-color-light !important;
  border-radius: 12px !important;
  background: white !important;
  border: 2px solid $main-color-light !important;
}

.error-tooltip {
	background: $reject !important;
	color: white !important;
}

/*styling overall snackbar*/
snack-bar-container {
	border-radius: 12px !important;
}

simple-snack-bar span {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
	margin: auto;
	text-align: center;
	color: #eee;
}

/*mat snackbar fail*/
.panelClass-error {
	background-color: $reject !important;
}

/*mat snackbar success*/
.panelClass-success {
	background-color: $approve !important;
}

/*mat snackbar loading*/
.panelClass-loading {
	background-color: $main-color-dark !important;
}

/*styling && positioning the empty illustrate in the body of page content*/
.no-data {
	text-align: center;
	margin-top: 125px;

	img {
		width: 160px;

		@media screen and (max-width: 414px) {
			width: 130px !important;
		}
	}

	p {
		// color: #808080a1;
		color: #241fa2;
		font-weight: bolder;
		margin-top: 10px !important;
		font-size: $xs-font;
	}

	.desc {
		display: flex;
		justify-content: center;
		position: relative;

		p {
			color: #3b3b5382;
			&:last-child {
				position: absolute;
				top: 30px;
			}
		}

		.middle {
			margin: 0 10px;
			color: $reject;
		}
	}

	button {
		margin-top: 34px;
	}
}

.search-data {
	@extend .no-data;
}

/*styling box of EDC status*/
.edc-status {
	background: #254a89;
	color: white;
	padding: 0px 10px;
	border-radius: 4px;
	padding-bottom: 1px;
	margin-left: 5px;
}

/*header of page content*/
.status-container {
	display: flex;
	flex-wrap: wrap;

	span {
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;

		&:not(:first-child) {
			margin-left: 16px;
		}
	}
}

.content-header {
	display: flex;
	align-items: baseline;

	.component-header {
		font-weight: bold !important;
		margin-bottom: 30px !important;
		font-size: 20px !important;
		color: $main-color-light;
		display: flex;
		flex-direction: column;
		@extend .status-container;
	}

	.btn-container {
		margin-left: auto;
		display: flex;
		align-items: center;
		// p {
		// 	font-size: small;
		// 	font-weight: unset;
		// 	color: black;
		// }
		button {
			&:not(:first-child) {
				margin-left: 15px !important;
				// font-size: small;
			}

			// &:nth-child(1),
			// &:nth-child(2),
			// &:nth-child(3),
			// &:nth-child(4) {
			//     margin-left: 15px;
			//     font-size: small;
			// }

			// &:nth-child(1) {
			//     margin-left: unset;
			// }

			// mat-icon {
			// 	font-size: 20px;
			// }
		}
	}
}

/* tab content */
.tab-content {
	.btn-container {
		display: flex;

		button {
			&:first-child {
				margin-left: auto;
			}

			&:not(:first-child) {
				margin-left: 20px;
			}
		}
	}
}

.required {
	color: red !important;
}

.required-label {
  &::after {
    content: " *";
    color: red !important;
  }
}

/*warning message require at top left of dialog of content*/
.msg-container {
	margin-left: auto;

	p {
		font-size: small;
		font-weight: normal;
		color: red;
	}
}

.stand-alone-msg-container {
	display: flex;

	p {
		margin-left: auto !important;
		font-size: small;
		font-weight: normal;
		color: red;
	}
}

.color-warn {
	color: $reject !important;
}

.color-return {
	color: $return !important;
}

.color-approve {
	color: $approve !important;
}

.bg-warn {
	background: $reject !important;
}

.bg-return {
	background: $return !important;
}

.bg-approve {
	background: $approve !important;
}

/*body of page content*/
.content-body {
	position: relative;

	.form-row {
		margin-left: -13px;
		align-items: baseline;
		position: relative;

    &.reset-baseline {
      align-items: unset !important;
    }

		.btn-container {
			margin-left: auto;
		}

		&.has-btn {
			align-items: unset;

			button {
				margin-top: 15px;
				margin-left: 14px;
			}
		}
	}

	.table-container {
		overflow: auto;
		width: 100%;
		margin-top: 12px;

		table {
			background: $backgroud-color;
		}

		.no-radius {
			border-radius: 0 !important;
		}

		.fix-overflow-bg {
			background: $main-color-light;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			right: -32px;
			padding: 16px;
		}

		.summary-row {
			background: white !important;

			&:hover {
				background-color: white !important;
			}

			td {
				padding-top: 20px;
				padding-bottom: 20px;
				color: $approve;
				font-weight: bold;
				font-size: 16px;

				&:first-child,
				&:last-child {
					border-radius: 0 !important;
				}
			}

			&:first-child {
				td {
					border-bottom: 2px dashed #0000001f !important;
				}
			}

			&:last-child {
				td {
					border-top: 2px dashed #0000001f !important;
				}
			}
		}

		.for-light-table {
			background: #f2f2f2 !important;

			&:hover {
				background-color: #f2f2f2 !important;
			}
		}

		.selectedRow {
			background: #f2f2f2 !important;
		}
	}
}

.desc-col {
	max-width: 200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-right: 20px;
}

/*page footer*/
.content-action {
	margin-top: 24px;

	button {
		&:not(:last-child) {
			margin-right: 20px;
		}
	}
}

/*some row class experience overflow problem - some case it should not overflow but it did.*/
.row {
	width: 100%;
}

/*set background of side left menu*/
.mat-drawer-inner-container {
	overflow: hidden !important;
	// background-color: $main-color-light;
	background-color: $bg-gradient-light;
}

/*make transition animation of image in carousel more faster*/
.carousel-item {
	transition: transform 0.2s ease-in-out !important;
}

/*make all dialog more rounder*/
.mat-dialog-container {
	border-radius: 12px !important;
}

/* flexible collumn btw 2 cards - left and right */
.flex {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	// justify-content: space-between;

	.left,
	.right {
		width: calc(50% - 12px);
		// min-width: 50%;
		// max-width: 200px;
	}
}

.d-flex {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	overflow: hidden;

	&.center {
		align-items: center;
		justify-content: center;
	}

	.left-form,
	.right-form {
		width: calc(50% - 10px);
		min-width: 200px;
	}

	.right-form {
		margin-left: 20px;
		padding-left: 24px;
	}

	@media (max-width: 820px) {
		.left-form,
		.right-form {
			width: 100%;
			min-width: unset;
		}

		.right-form {
			margin-left: 0;
			padding-left: 0;
		}

		.border-left {
			border: none !important;
		}
	}
}

/* style right card */
.right-card {
	border-left: 1px solid $border-color;
	padding-left: 30px !important;
}

/* style width time picker */
.body {
	width: 520px !important;
}

.mat-toolbar.mat-primary {
	background-color: $main-color-light !important;
}

/* style checkbox holder */
.checkbox-holder {
	margin: 0px 0px 14px 12px;
	mat-checkbox {
		font-family: "SegoeUI", "KhmerOSbattambang" !important;
		display: inline-block;
		width: 50%;
		padding-top: 10px;
		font-size: $xs-font;
	}
}

.checkbox-holder-form {
	background: white;
	width: 100%;
	height: 56px;
	border-radius: 12px;
	margin: 0;
	margin-top: 8px;
	padding: 5px 12px 0px 12px;
	margin-left: 0;
}

/* styling google map message when scrolling */
.gm-style-mot {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
	color: white !important;
	font-weight: normal !important;
	font-size: 24px !important;
}

.mat-tree-node {
	button {
		margin-top: -14px !important;
	}
}

/* styling cdk select pop up content */
.mat-select-panel {
	// min-height: 200px !important;
	// padding: 12px !important;
	max-height: 320px !important;
	font-size: $global-form-font-size !important;
	border-radius: 12px !important;
}

div.select-content {
	min-height: 200px;
	padding: 12px 24px 24px 24px;

	.search-field {
		mat-option {
			background-color: white !important;
			padding: 0 !important;
		}

		.mat-form-field-appearance-legacy .mat-form-field-infix {
			mat-icon {
				margin-right: -14px;
				padding-top: 20px;
				height: 48px;
				height: 65px !important;
			}

			input {
				padding-left: 20px;
				padding-bottom: 4px;
				font-size: 18px;
				height: 61px;
			}
		}

		.mat-form-field-appearance-legacy .mat-form-field-underline {
			height: 14px !important;
			background-color: #b8c5d5 !important;
		}

		.mat-form-field.mat-focused .mat-form-field-ripple {
			background-color: darken($color: #b8c5d5, $amount: 20%);
		}

		.mat-form-field-appearance-legacy .mat-form-field-label {
			margin-left: 20px !important;
		}

		.search-button {
			position: absolute;
			top: 34px;
			right: 24px;
		}

		.user-options {
			background: transparent !important;
			height: 60px;
			width: calc(100% - 140px);
		}
	}

	.search-multiple {
		display: flex;

		.user-options {
			width: 100%;
		}

		.btn-container {
			display: flex;
			align-items: center;
			margin-left: 20px;

			button {
				&:first-child {
					margin-left: auto;
				}

				&:not(:first-child) {
					margin-left: 20px;
				}
			}

			.search-button {
				position: unset;
				top: unset;
				right: unset;
			}
		}
	}

	div.select-list {
		overflow: auto;
		min-height: 100px;
		// height: 220px;
		margin-top: 12px;

		.option-list {
			border-radius: 12px;
			transition: 0.22s;
			margin: 4px 29px 4px 0;
			padding-top: 10px;

			&:hover {
				background-color: #b9a5341c;
			}
		}

		.no-data {
			margin-top: 32px;

			img {
				width: 146px;
			}
		}
	}
}

div.select-content div.select-list {
	height: 212px !important;
}

.option-name {
	display: flex;
	justify-content: space-between;

	span {
		min-width: 200px;
		width: 320px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: justify;
		font-size: $global-form-font-size;

		&:first-child {
			&::after {
				content: " ";
			}
		}
	}
}

.selected-option {
	@extend .option-name;
	background: #ffffff;
	z-index: 1;
	position: absolute;
	top: 14px !important;
	left: 12px !important;
	width: calc(100% - 30px) !important;
	height: 26px !important;
	overflow: hidden;
	font-weight: normal !important;
	line-height: 28px;
}

.option-name span,
.selected-option span {
	width: unset !important;
	min-width: auto !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
	background-color: #b9a5341c !important;
	color: #b9a431 !important;
	font-weight: bold;
}

/* styling input type time */
input[type="time"]::-webkit-calendar-picker-indicator {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 68px;
	top: -6px;
}

/* date picker month year only */
.mat-calendar {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

.month-year-picker .mat-calendar-period-button {
	pointer-events: none;
}

.month-year-picker .mat-calendar-arrow {
	display: none;
}

/* styling mat-error */
.err-msg {
	margin-top: -6px;
	font-size: 0.75rem;
	margin-bottom: 12px;
}

/* styling tracking files list */
.file-list {
	list-style: none;
	line-height: 12px;
	padding-left: 0;
	width: 100%;

	li {
		border: 1px solid #eaeaea;
		margin: 12px 0;
		padding: 2px 12px;
		border-radius: 12px;
		font-weight: normal;
		display: flex;
		align-items: center;
		line-height: 24px;

		.file-action {
			margin-left: auto;
			min-width: 100px;
			display: flex;
			justify-content: center;

			button {
				position: relative !important;

				&:first-child {
					color: $main-color-dark !important;
				}

				&:nth-child(2) {
					color: $approve !important;
				}
			}
		}
	}
}

/* styling loading text on screen - snackbar */
// .loading-text {
//     position: absolute !important;
//     top: 50% !important;
//     left: 50% !important;
//     transform: translate(-50%, -60%) !important;
//     bottom: -1% !important;
//     background: #bdbdbda1 !important;
//     padding: 14px 16px !important;
//     width: 345px !important;
//     height: 50px;
//     border-radius: 12px !important;
//     display: flex !important;
//     justify-content: center !important;
//     align-items: center !important;
//     color: #3b3b53;

// 	.mat-progress-spinner circle,
// 	.mat-spinner circle {
// 		stroke: $main-color-dark !important;
// 	}

// 	p {
// 		font-size: $sm-font !important;
// 		color: #3b3b53 !important;
// 		text-align: center !important;
// 		margin: 0 !important;
// 		margin-left: 12px !important;
// 	}
// }

.w-100 {
	width: 100% !important;
}

.align-right {
	text-align: right !important;
}

hr.dot {
	border: 1px solid dotted !important;
}

.text-nowrap {
	white-space: nowrap;
}

.no-padding {
	padding: 0 !important;
}

.menu-svg-icon {
	margin-top: -6px;

	svg :not(path[fill="none"]) {
		fill: $icon-color !important;
	}
}
