@import "./m_variables.scss";

table.tbl-data {
	width: unset !important;
	min-width: unset !important;
	margin-left: 9px;

	@media (max-width: 414px) {
		width: 100% !important;
	}

	tr {
		td {
			min-height: 36px;
			padding: 5px 0px;
			vertical-align: top;
		}

		td.label {
			color: $label-color !important;
			width: max-content;
			font-size: 14px;
			padding-right: 30px;
			line-height: 27px;
			min-width: 130px;
			min-width: 200px !important;
			width: calc(100vw - 1680px) !important;
			max-width: 500px !important;
			font-family: "SegoeUI", "KhmerOSbattambang" !important;

			@media (max-width: 414px) {
				padding-right: 10px;
			}
		}

		td.value {
			color: $main-color-dark !important;
			width: max-content;
			font-size: 14px;
			line-height: 27px;
			font-family: "SegoeUI", "KhmerOSbattambang" !important;
			@media (max-width: 414px) {
				text-align: end;
			}

			span {
				display: flex;
				flex-direction: column;

				small {
					color: $label-color;
					&::before {
						content: "(";
					}

					&::after {
						content: ")";
					}
				}
			}
		}
	}

	tr.tr-empty {
		td {
			height: 38px;
		}

		@media (max-width: 414px) {
			display: none !important;
		}
	}
}

table.tracking-msg {
	margin-left: 21px;

	@media (max-width: 414px) {
		margin: 0px;
	}
}

table.m-table {
	border-radius: 8px;
	margin-bottom: 20px;
	background-color: $backgroud-color;

	p {
		font-weight: normal !important;
		color: #3b3b53;
		font-size: $xs-font;
	}

	th {
		color: white !important;
		background-color: $main-color-light !important;
		font-size: $xs-font !important;
		font-weight: normal !important;
		padding: 16px 0;
		vertical-align: top !important;
		border-bottom: none !important;

		&:first-child {
			border-radius: 12px 0 0 12px;
			min-width: 60px;
		}

		&:last-child {
			border-radius: 0 12px 12px 0;
		}

		p {
			margin: 0 !important;
		}
	}

	tr {
		&:first-child {
			td {
				&:first-child {
					border-radius: 12px 0 0 0px;
				}

				&:last-child {
					border-radius: 0 12px 0px 0;
				}
			}
		}

		&:last-child {
			td {
				&:first-child {
					border-radius: 0 0 0 12px;
				}

				&:last-child {
					border-radius: 0 0 12px 0;
				}
			}
		}

		&:nth-child(even) {
			background: lighten($color: $main-color-light, $amount: 90%);
			// background-color: $main-color-dark !important;
		}

		&:hover {
			transition: 0.1s;
			background: darken($color: $backgroud-color, $amount: 5%) !important;
			// background-color: $main-color-light !important;
		}

		td {
			color: $main-color-light;
			border-bottom: 0px solid white !important;
			// background-color: $backgroud-color;
			padding: 4px 0;
			font-size: $xs-font;

			mat-icon {
				font-size: $lg-font;
			}
		}
	}

	&.sticky {
		th {
			&:first-child {
				background-color: #f2f2f2 !important;
				border-radius: 0px !important;
				min-width: 60px;

				p {
					margin: 0px;
					padding: 16px 0px 16px 10px;
					background-color: #3b3b53 !important;
					font-weight: normal !important;
					color: white !important;
					border-radius: 12px 0px 0px 12px;

					position: absolute;
					left: 0px;
					top: 0;
					width: 100%;
					height: 100%;
					font-size: $xs-font !important;

					mat-checkbox {
						margin-left: 14px;
					}
				}
			}

			&:last-child {
				background-color: #f2f2f2 !important;
				border-radius: 0px !important;
				min-width: 95px;

				p {
					margin: 0px;
					padding: 16px 16px 0px 24px;
					background-color: #3b3b53;
					font-weight: normal !important;
					color: white !important;
					border-radius: 0px 12px 12px 0px;

					position: absolute;
					left: 0px;
					top: 0;
					width: 100%;
					height: 100%;
					font-size: $xs-font !important;
				}
			}
		}
	}

	.m-chip-center {
		.mat-chip-list-wrapper {
			justify-content: center !important;
		}

		.mat-chip:focus {
			background: inherit;
		}
	}

	.mat-slide-toggle-thumb {
		background: lightgray !important;
		height: 16px !important;
		width: 16px !important;
	}

	.mat-slide-toggle-bar {
		background: white !important;
		border: 1px solid lightgray !important;
		width: 30px !important;
		height: 12px !important;
	}

	.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background: white;
		// border: 1px solid #32a4ac !important;
		border: 1px solid darken($color: $icon-color, $amount: 5%) !important;
	}

	.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
		// background: #32a4ac !important;
		background: darken($color: $icon-color, $amount: 5%) !important;
	}

	.slide-toggle-disabled {
		&.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
			background: white;
			border: 1px solid #d1d1d1 !important;
		}

		&.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
			background: #d1d1d1 !important;
		}
	}

	.action-btns {
		button {
			padding: unset !important;
			margin-left: 8px;
			width: 30px;
			height: 30px;

			mat-icon {
				font-size: 16px !important;
				margin-top: -13px !important;
				vertical-align: middle !important;
			}
		}

		.m-icon-button-resume {
			color: #00ae00 !important;
			// background: url("../../assets/icons/auto_renew.svg") no-repeat !important;
		}

		.m-icon-button-suspend {
			// background: url("../../assets/icons/pause.svg") no-repeat !important;
			color: #de9d24 !important;
		}

		.m-icon-button-close {
			// background: url("../../assets/icons/do_not_disturb_on_black_24dp.svg") no-repeat !important;
			color: #ff3c3c !important;
		}

		.m-icon-button-edit {
			color: #de9d24 !important;
			border: 1px solid #de9d24 !important;
		}

		.m-icon-button-delete {
			// background: url("../../assets/icons/delete_icon.svg") !important;
			color: #ff3c3c !important;
		}
	}
}

table.m-table-light {
	@extend .m-table;

	th {
		color: $main-color-light !important;
		background-color: white !important;
		font-weight: bold !important;
	}

	tr {
		&:first-child {
			td {
				&:first-child {
					border-radius: 0;
				}

				&:last-child {
					border-radius: 0;
				}
			}
		}

		&:last-child {
			td {
				&:first-child {
					border-radius: 0;
				}

				&:last-child {
					border-radius: 0;
				}
			}
		}

		&:nth-child(even) {
			// background: $backgroud-color !important;
			background: white !important;
		}

		&:hover {
			transition: 0.1s;
			background: darken($color: $backgroud-color, $amount: 5%) !important;
			// background-color: $main-color-light !important;
		}

		td {
			border-bottom: 1px solid #ffffff9e !important;
		}
	}
}

table.m-table-border {
	@extend .m-table;
	border-collapse: collapse;

	th {
		color: white !important;
		background-color: $main-color-light !important;
		font-weight: bold !important;
		border: 1px solid $backgroud-color;
		text-align: center;
		vertical-align: middle !important;
		font-weight: normal !important;
		padding: 0 !important;

		&:first-child {
			border-radius: 0;
			min-width: 60px;
		}

		&:last-child {
			border-radius: 0;
		}
	}

	tr {
		&:first-child {
			th {
				&:first-child {
					border-radius: 12px 0 0 0;
					min-width: 60px;
				}

				&:last-child {
					border-radius: 0 12px 0 0;
				}
			}

			td {
				&:first-child {
					border-radius: 0;
				}

				&:last-child {
					border-radius: 0;
				}
			}
		}

		&:last-child {
			td {
				&:first-child {
					border-radius: 0;
				}

				&:last-child {
					border-radius: 0;
				}
			}
		}

		&:nth-child(even) {
			// background: $backgroud-color !important;
			background: white;
		}

		&:hover {
			transition: 0.1s;
			background: darken($color: $backgroud-color, $amount: 5%) !important;
			// background-color: $main-color-light !important;
		}

		td {
			border-bottom: 1px solid #ffffff9e !important;
			padding-left: 0 !important;
		}
	}
}

table.m-table-payment {
	@extend .m-table;

	tr {
		&:first-child {
			background: darken($color: $backgroud-color, $amount: 5%) !important;
		}

		&:last-child {
			background: darken($color: $backgroud-color, $amount: 5%) !important;
		}
	}
}

.m-table-container {
	padding: 5px 11px;
	overflow-x: auto;

	.m-table {
		margin-bottom: 0;
	}
}

.m-paginator {
	width: 100%;
}

.m-right {
	text-align: right !important;
}

.m-center {
	text-align: center !important;
}

.mat-paginator {
	background-color: $backgroud-color !important;
	height: 85px;
}
