button {
            &.approved {
                        // background-color: #79be61 !important;
                        // background-color: #79be61d9  !important;
                        background-color: #4f9d34d9   !important;
                        color: white !important;
            }

            &.return {
                        background-color: #b76d20d9 !important;
                        color: white !important;
            }
            
            &.rejected {
                        background-color: #c12626d9  !important;
                        color: white !important;
            }
            
            &.inprogress {
                        background-color: #bdab23d9 !important;
                        color: white !important;
            }
            
            &.primary {
                        background-color: $main-color-dark !important;
                        color: white !important;
            }
}
