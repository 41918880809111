.mt-neg-8 {
    margin-top: -8px !important;
}

.mt-neg-12 {
    margin-top: -12px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}
