.mw-70 {
    min-width: 70px;
}

.mw-100 {
    min-width: 100px;
}

.mw-210 {
    min-width: 210px;
}

.w-120 {
    // width: 120% !important;
    width: 100% !important;
}

.w-150 {
    // width: 150% !important;
    width: 100% !important;
}

.w-170 {
    // width: 170% !important;
    width: 100% !important;
}

.w-200 {
    // width: 200% !important;
    width: 100% !important;
}

.w-220 {
    // width: 220% !important;
    width: 100% !important;
}

.w-250 {
    // width: 250% !important;
    width: 100% !important;
}

.w-270 {
    // width: 270% !important;
    width: 100% !important;
}

.w-300 {
    // width: 300% !important;
    width: 100% !important;
}

.w-330 {
    // width: 330% !important;
    width: 100% !important;
}

.w-370 {
    // width: 370% !important;
    width: 100% !important;
}

.w-400 {
    // width: 400% !important;
    width: 100% !important;
}

.w-420 {
    // width: 420% !important;
    width: 100% !important;
}

.w-450 {
    // width: 450% !important;
    width: 100% !important;
}

.w-500 {
    // width: 500% !important;
    width: 100% !important;
}

.w-520 {
    // width: 520% !important;
    width: 100% !important;
}