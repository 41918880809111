@import "./m_variables.scss";

.mat-form-field {
    color: $main-color-dark !important;
    font-family: "SegoeUI", "KhmerOSbattambang" !important;
    line-height: 22px !important;
    width: 100%;
}

// .mat-form-field-appearance-outline .mat-form-field-outline {

// }

.mat-form-field-appearance-outline .mat-form-field-label {
    top: 25px;
    margin-top: -3px;
}

.mat-select,
.mat-option {
    font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

.mat-datepicker-content {
    color: $main-color-dark !important;
    background-color: white !important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    color: $main-color-dark !important;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

mat-progress-bar {
    // position: absolute !important;
    top: 45px;
    // left: 0;
    // height: 2px !important;
}

table.mat-table {
    width: 100%;
    border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0 5px;
    font-family: "SegoeUI", "KhmerOSbattambang" !important;

    thead th {
        vertical-align: top;
        background-color: $table-th-color;
        color: $main-color-dark;
        font-size: 14px;
        font-weight: bold;
    }

    tr {
        &:hover {
            background-color: $table-hover-bg-color;
        }

        th,
        td {
            // padding: 5px 10px !important;
            vertical-align: middle;
            line-height: 27px;
        }

        th.center-col,
        td.center-col {
            text-align: center;

            mat-slide-toggle {
                margin-right: 12px;
            }
        }

        th.right-col,
        td.right-col {
            text-align: right;

            mat-slide-toggle {
                margin-right: 12px;
            }
        }

        th.left-col,
        td.left-col {
            text-align: left;

            mat-slide-toggle {
                margin-right: 12px;
            }
        }

        th.set-vert,
        td.set-vert {
            vertical-align: middle;

            p {
                margin-bottom: 0;
            }

            mat-icon {
                margin-top: -16px !important;
            }

            mat-checkbox {
                margin-top: 4px !important;
            }
        }

        td.statusNicon {
            width: 160px;
            display: flex;
            align-items: baseline;

            mat-icon {
                width: $md-font;
                margin-left: 12px;
            }
        }
    }

    tr.mat-header-row {
        height: 44px;
    }

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        padding-left: 24px;
        // border-bottom: 1px solid #f2f2f2;
    }

    & + p.no-data {
        text-align: center;
        font-size: 14px;
        margin-top: 20px;

        & + .mat-paginator {
            display: none;
        }
    }
}

.mat-dialog-title h3,
p {
    font-family: "SegoeUI", "KhmerOSbattambang" !important;
}

// .mat-dialog-container {
//     padding: 0px !important;
// }

.mat-dialog-content {
    h3 {
        font-size: 16px;
        font-family: "SegoeUI", "KhmerOSbattambang" !important;
    }

    h4 {
        font-family: "SegoeUI", "KhmerOSbattambang" !important;
    }

    p {
        font-size: 14px;
        font-family: "SegoeUI", "KhmerOSbattambang" !important;
    }

    padding: 4px 24px !important;
}

.mat-menu-panel {
    max-width: unset !important;
    min-width: 140px !important;
    border-radius: 12px !important;
    // box-shadow: 2px 2px 6px 2px #e3e3e3 !important;
}

.mat-menu-item {
    color: $main-color-dark !important;
    font-family: "SegoeUI", "KhmerOSbattambang" !important;
    font-size: $xs-font !important;
    font-weight: bold !important;
    min-width: 180px;

    mat-icon {
        color: $main-color-dark !important;
        border-right: 1px solid #34344f26;
        padding: 0px 35px 24px 0px;
        font-size: 20px;
    }

    .svg-icon {
        padding: 0 !important;
        width: 35px !important;
        padding-right: 16px !important;

        svg :not(path[fill="none"]) {
            fill: $main-color-dark !important;
        }
    }

    // &:hover {
    //     // color: $icon-color !important;

    //     mat-icon {
    //         color: $icon-color !important;
    //     }

    //     .svg-icon {
    //         svg :not(path[fill="none"]) {
    //             fill: $icon-color;
    //         }
    //     }
    // }

    .modified-color {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%) !important;
    }
}

mat-icon {
    vertical-align: middle;
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-prefix
    .mat-icon-button
    .mat-datepicker-toggle-default-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button
    .mat-datepicker-toggle-default-icon {
    margin: auto !important;
    margin-top: 12px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $icon-color !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: $label-color !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $icon-color !important;
}

.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: $label-color !important;
}

.mat-checkbox-background,
.mat-checkbox-frame {
    border-radius: 4px !important;
    border-color: #b8c5d5 !important;
}

.m-divider-dashed {
    border-top-width: 2px !important;
    border-top-style: dashed !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $icon-color !important;
}
