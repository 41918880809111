.dialog-btn-box {
            float: right;

            button {
                        margin-left: 10px;
                        margin-bottom: 5px;
            }
}

.mat-dialog-actions {
            button {
                        &:not(:first-child) {
                                    margin-left: 15px !important;
                        }
            }

            margin-bottom: -16px !important;
}

.confirm-dialog-btn-box {
            margin: 0px auto;
            width: max-content;

            button {
                        margin: 10px 5px;
            }
}

.title-dialog {
            display: flex !important;
            align-items: flex-start;
            position: relative;
            margin-bottom: 0 !important;

            h3 {
                        font-weight: bold !important;
                        font-size: 20px !important;
            }

            span.sub-title {
                        position: absolute;
                        font-size: 12px;
                        top: 26px;
            }

            .btn-container {
                        display: flex;
                        margin-left: auto;
            }

            .status-container {
                display: flex;
                margin-top: -10px;
            
                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: normal;
                    font-family: "SegoeUI", "KhmerOSbattambang" !important;
            
                    &:not(:first-child) {
                        margin-left: 16px;
                    }
                }
            }
}
