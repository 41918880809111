@import "./m_variables.scss";

@font-face {
	font-family: "KhmerOSbattambang";
	src: 
		local('Khmer OS Battambang'),
		local('Khmer OS Battambang Regular'),
		url("../fonts/KhmerOSbattambang.ttf");
	font-display: swap;
}

@font-face {
	font-family: "SegoeUI";
	src: 
		local('Khmer OS Battambang'),
		local('Khmer OS Battambang Regular'),
		url("../fonts/segoeui.ttf");
	font-display: swap;
}

.progress-tracker {
	font-family: "SegoeUI", "KhmerOSbattambang" !important;
	margin: 15px;
}

.title {
	color: #313131;

	.progress-title {
		margin: 0;

		span {
			margin-right: 10px;

			&.approve {
				color: $approve !important;
			}

			&.checked {
				color: $check !important;
			}

			&.inprogress {
				color: $inprogress !important;
			}

			&.reject {
				color: $reject !important;
			}

			&.return {
				color: $return !important;
			}

			&.new {
				color: $new !important;
			}

			&.assign {
				color: $assign !important;
			}

			// &.highlight {
			// 	color: $highlight !important;
			// }

			// &.submit {
			// 	color: $submit !important;
			// }

			// &.closed {
			// 	color: $closed !important;
			// }

			// &.suspended {
			// 	color: $suspended !important;
			// }

			// &.reopened {
			// 	color: $re_open !important;
			// }

			&.normal {
				font-weight: normal !important;
			}
		}
	}

	p.item {
		margin-bottom: 0px;
		margin-left: 0 !important;
		font-weight: normal;

		label {
			font-weight: normal;
			color: $label-color;
		}

		span {
			font-weight: normal !important;
			color: $main-color-dark;
		}

		&.approve {
			color: $approve !important;
		}

		&.inprogress {
			color: $inprogress !important;
		}

		&.reject {
			color: $reject !important;
		}

		&.return {
			color: $return !important;
		}

		&.new {
			color: $new !important;
		}

		&.assign {
			color: $assign !important;
		}

		&.checked {
			color: $check !important;
		}
	}

	.date {
		color: #a3a3a3;
	}
}

.progress-marker {
	padding-top: 8px;
}

.progress-marker::before {
	width: 25px;
	height: 25px;
	border: 8px solid #f3f3f5;
	background-color: $approve;
}

.progress-marker::after {
	z-index: 1 !important;
	left: -8px !important;
	top: 27px !important;
	height: calc(100% - 19px) !important;
	width: 2px !important;
	background-color: $icon-color !important;
}

.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
	background-color: $main-color-light;
}

.progress-step.is-rejected .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
	background-color: $main-color-light;
}

.progress-step.new-request .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
	background-color: $main-color-light;
}

.left-box {
	padding-top: 4px;
}

.main-box {
	padding: 4px 12px 20px 30px;
	width: 100%;

	.progress-text {
		display: flex;
		width: 100%;
		padding: 4px 12px 12px 33px;
		font-family: "SegoeUI", "KhmerOSbattambang" !important;

		.title {
			margin-bottom: 0 !important;
			width: 100%;
			position: relative;

			button {
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		p,
		h4 {
			padding-bottom: 0.7em;
			font-family: "SegoeUI", "KhmerOSbattambang" !important;
		}

		p {
			label {
				margin-bottom: 0;
				font-family: "SegoeUI", "KhmerOSbattambang" !important;
			}
		}

		h4.date {
			margin-bottom: 0;
			font-family: "SegoeUI", "KhmerOSbattambang" !important;
		}
	}

	.content {
		.new-request {
			color: $new;
		}

		ul {
			margin-bottom: 15px;

			li {
				margin: 10px 0px;
			}
		}
	}
}

.new-main-box {
	width: 100%;

	.progress-text {
		// background-color: #3b3b530f;
		border-radius: 12px;
		width: 100%;
		border: 1px dashed #3b3b5347;

		.title {
			padding: 16px 16px 0 16px;
			width: 100%;

			.progress-title {
				border-bottom: 2px dashed #eaeaea;
				width: 100%;
				margin-bottom: 16px;
				display: flex;

				span {
					mat-icon {
						margin-right: 16px;
						margin-top: -8px;
					}
				}
			}

			p.item {
				color: $main-color-dark;
			}
		}
	}
}

.progress-tracker--vertical .progress-text {
	padding: 0 12px 5px 0px;
}

.progress-step:hover .progress-marker::before {
  opacity: 0.7;
  background: #b6b6b6 !important;
}

.li-empty {
	border-bottom: 1px solid #eaeaea;
}

.progress-step h4.date {
	min-width: 185px;
}

.set-gab {
	gap: 40px;

	.ah {
		height: fit-content;
		min-height: 125px;
	}

	.divider {
		opacity: 0.3;
	}

	.set-m-l {
		margin-left: 10px !important;
	}

	.s-m {
		margin: 0;
	}

	.title-2 {
		margin-left: 10px;

		p {
			margin-top: 4px;
			font-size: 15px;
			margin-left: 12px !important;
			color: #2a5298 !important;
			font-weight: 700;
		}

		mat-icon {
			height: 20px;
			color: #2a5298 !important;
		}
	}

	.normal-table {
		tr {
			background: none !important;

			&:hover {
				background: none !important;
			}

			td {
				padding: 7px 0;

				.label {
					width: 200px;
					color: black !important;
					display: flex;
					font-family: "SegoeUI", "KhmerOSbattambang" !important;

					&::after {
						content: ("៖");
						margin-left: auto;
						margin-right: 12px;
					}
				}
			}

			.label {
				color: black !important;
				width: 150px;
				font-family: "SegoeUI", "KhmerOSbattambang" !important;
			}
		}

		.value {
			font-family: "SegoeUI", "KhmerOSbattambang" !important;
			word-break: break-all;
			word-wrap: break-word;
			vertical-align: baseline;
			color: #2a5298;
		}
	}
}
