$logo-color-dark: #00513f;
$logo-color-light: #02644e;
$main-color-dark: #34344f;
$main-color-light: #3b3b53;

// $main-color-dark: #005240;
// $main-color-light: #02644e;
$backgroud-color: #f2f2f2;

$table-th-color: #f2f2f2;
$table-hover-bg-color: white;

$bg-gradient-dark: repeating-linear-gradient(135deg, hsla(35,0%,71%,0.09) 0px, hsla(35,0%,71%,0.09) 1px,transparent 1px, transparent 11px),
                    repeating-linear-gradient(45deg, hsla(35,0%,71%,0.09) 0px, hsla(35,0%,71%,0.09) 1px,transparent 1px, transparent 11px),
                    linear-gradient(90deg, $main-color-dark, $main-color-dark);
                    // linear-gradient(90deg, rgb(52,52,79), rgb(52,52,79));

$bg-gradient-light: repeating-linear-gradient(135deg, rgba(255,255,255, 0.04) 0px, rgba(255,255,255, 0.04) 1px,transparent 1px, transparent 11px),
                    repeating-linear-gradient(45deg, rgba(255,255,255, 0.04) 0px, rgba(255,255,255, 0.04) 1px,transparent 1px, transparent 11px),
                    linear-gradient(90deg, $main-color-light, $main-color-light);
                    // linear-gradient(90deg, rgb(59,59,83), rgb(59,59,83));
                    
// $bg-gradient-light: repeating-linear-gradient(135deg, rgba(153,153,153, 0.09) 0px, rgba(153,153,153, 0.09) 1px,transparent 1px, transparent 11px),repeating-linear-gradient(45deg, rgba(153,153,153, 0.09) 0px, rgba(153,153,153, 0.09) 1px,transparent 1px, transparent 11px),linear-gradient(90deg, rgb(59,59,83),rgb(59,59,83));
// $bg-gradient-light: repeating-linear-gradient(135deg, hsla(35,0%,71%,0.09) 0px, hsla(35,0%,71%,0.09) 1px,transparent 1px, transparent 11px),repeating-linear-gradient(45deg, hsla(35,0%,71%,0.09) 0px, hsla(35,0%,71%,0.09) 1px,transparent 1px, transparent 11px),linear-gradient(90deg, rgb(59,59,83),rgb(59,59,83));

// $success: #03a403;
// $pending: #b7b7b7;
// $in-progress: #c073ec;

$label-color: #A5A5A5;
$border-color: #EAEAEA;
$icon-color: #ffd803;
// $icon-color: #fdb841;
// $icon-color: #ffffff;

/*------- STATUS COLOR ---------*/
$approve: #4f9d34d9;
$reject: #c12626d9;
$return: #b76d20d9;
$inprogress: #bdab23d9;
$assign: #ee891d;
$primary: $main-color-dark;
$new: #8f97a6;
$check: #255dc5;



/*------- FONT SIZES ---------*/
$xs-font: 12px;
$sm-font: 14px;
$md-font: 16px;
$lg-font: 20px;
$xl-font: 24px;
$global-form-font-size: $sm-font;