@import "./m_variables.scss";

.input-color-active {
	background: white;
	color: black !important;
	border: 1px solid #00306747;
}

.input-color-disabled {
	background: #f7f7f7 !important;
	color: black !important;
	border: 1px solid #dbdbdb !important;
}

textarea {
	font-size: $global-form-font-size !important;
}

.m-form {
	mat-label {
		// color: $main-color-dark !important;
		color: #9a9a9a !important;
		font-size: $global-form-font-size !important;
		margin-left: 12px !important;
		// line-height: calc($sm-font + 18px);
	}

	input {
		margin-left: 12px;
		width: calc(100% - 12px);
		line-height: 30px !important;
		margin-top: -4px !important;
		margin-bottom: -4px;
		font-size: $global-form-font-size;
	}

	.m-icon {
		margin-top: 12px !important;
		color: #d5a600;
		font-size: 24px !important;

		svg {
			margin-top: -12px !important;
		}
	}

  .m-svg-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;

    // svg :not(path[fill="none"]) {
    //   fill: #d5a600 !important;
    // }
  }

	.m-icon-normal {
		color: $main-color-light;
	}

	.clickable {
		cursor: pointer;
	}

	&.mat-form-field-appearance-outline .mat-form-field-wrapper {
		padding-bottom: 0 !important;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-start {
		border-radius: 12px 0 0 12px !important; // set border radius of control at top and bottom start
		width: 41px !important;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-end {
		border-radius: 0 12px 12px 0 !important; // set border radius of control at top and bottom end
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline {
		background: white !important;
		color: #ffffff47; // border color of date input
		border-radius: 13px;
		top: 0.7em !important;
	}

	&.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
		background: #f7f7f7 !important;
	}

	&.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
		color: #00306747; // border color when active or focus
	}

	&.mat-form-field-appearance-outline .mat-form-field-prefix,
	&.mat-form-field-appearance-outline .mat-form-field-suffix {
		top: 0.45em;
	}

	&.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
	.mat-form-field-appearance-outline.mat-form-field-can-float
		.mat-input-server:focus
		+ .mat-form-field-label-wrapper
		.mat-form-field-label {
		z-index: 1;
		// transform: translate(0.2em, -1.39375em) scale(0.75); // transfor m translate x y position of label
		// transform: translate(0.2em, -1.2em) scale(0.75); // transfor m translate x y position of label
		transform: translate(-0.15em, -1.6em) scale(1);
	}
}

.no-prefix-icon {
	&.mat-form-field-appearance-outline .mat-form-field-outline-start {
		width: 16px !important;
	}
}

/* normal input formcontol */
.m-form-input {
	@extend .m-form;
}

/* styling for date control */
.m-form-date {
	@extend .m-form;
	.input-group {
		input {
			height: 45px !important;
			margin-top: 5px;
			border-radius: 11px;
			border: unset !important;
		}
	}

	mat-datepicker-toggle {
		margin-top: 9px !important;
	}
}

/* styling for select control */
.m-form-select {
	@extend .m-form;

	.mat-select-trigger {
		display: flex;

		.mat-select-value {
			display: unset !important;
			max-width: unset !important;
			overflow-x: hidden !important;
			overflow-y: visible !important;
			height: 27px;
		}

		.mat-select-arrow-wrapper {
			margin-left: auto;
			// margin-top: 10px;
			margin-top: 0;
			height: 0px;
		}
	}


	.mat-select-value-text {
		margin-left: 12px !important;
		// line-height: 27px;
		font-size: $global-form-font-size !important;
	}

	&.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
		background: #f7f7f7 !important;
		color: #dbdbdb;

		.selected-option {
			background: #f7f7f7 !important;
		}
		// border: 1px solid #dbdbdb !important;
	}

	&.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
		transform: translateY(8%) !important;
	}

	.mat-select-arrow {
		color: #d5a600 !important;
	}

	&.mat-form-field-flex {
		align-items: unset !important;
	}
}

.red-disable {
	&.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
		background: #f7f7f7 !important;
		color: #f44336;
	}
}

.m-form-select-checkbox-kh {
	@extend .m-form-select;

	&.mat-form-field-appearance-outline .mat-form-field-outline-start {
		width: 150px !important;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
		width: 150px !important;
	}

	.mat-form-field-infix {
		margin-left: 135px !important;
	}

	mat-checkbox {
		position: absolute;
		height: 57px;
		top: -2px;
		left: -146px;
		background: $bg-gradient-light;
		padding: 15px 16px 12px 16px;
		border-radius: 12px 0px 0px 12px;
		width: fit-content;

		label {
			color: white;
			font-family: "SegoeUI", "KhmerOSbattambang" !important;
		}
	}

	mat-select {
		position: absolute !important;
		left: 14px !important;
		width: calc(100% - 16px) !important;
		top: 22px;
	}

	.default-height {
		height: 16px;
	}
}

.m-form-select-checkbox-en {
	@extend .m-form-select;

	&.mat-form-field-appearance-outline .mat-form-field-outline-start {
		width: 204px !important;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
		width: 204px !important;
	}

	.mat-form-field-infix {
		margin-left: 188px !important;
	}

	mat-checkbox {
		position: absolute;
		height: 57px;
		top: -2px;
		left: -198px;
		background: $bg-gradient-light;
		padding: 15px 16px 12px 16px;
		border-radius: 12px 0px 0px 12px;
		width: fit-content;

		label {
			color: white;
			font-family: "SegoeUI", "KhmerOSbattambang" !important;
		}
	}

	mat-select {
		position: absolute !important;
		left: 14px !important;
		width: calc(100% - 16px) !important;
	}

	.default-height {
		height: 16px;
	}
}

/* styling select control with input inside it */
.m-form-select-input {
	@extend .m-form-select;

	mat-select {
		div.select-list {
			min-height: 200px;
			padding: 20px 12px 12px 12px;
			background-color: yellow !important;

			mat-option {
				.mat-option-text {
					.mat-form-field {
						.mat-form-field-appearance-legacy .mat-form-field-wrapper {
							.mat-form-field-flex {
								.mat-form-field-appearance-legacy .mat-form-field-infix {
									.mat-form-field-label-wrapper {
										.mat-form-field-appearance-legacy .mat-form-field-label {
											mat-lable {
												margin-left: 20px !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* input form control rounded */
.m-form-round {
	input {
		border: none !important;
		border-radius: 24px;
		padding-left: 20px;
		padding-right: 50px;
		position: relative;
	}

	mat-icon {
		position: absolute;
		right: 24px;
		top: 10px;
		color: #adadad;
		user-select: none;
	}

	.dropdown {
		.dropdown-toggle {
			border: none;
			border-radius: 24px;
			padding-left: 20px;

			p {
				font-size: unset;
				font-weight: unset;
			}

			&::after {
				content: none;
			}

			.default {
				color: #adadad;
			}
		}

		mat-icon {
			position: absolute;
			right: 0;
			top: 10px;
			color: #adadad;
			user-select: none;
		}

		.dropdown-menu {
			p {
				font-weight: unset;
			}
		}
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #adadad !important;
		opacity: 1 !important; /* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #adadad !important;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #adadad !important;
	}
}

.show-outline {
	&.mat-form-field-appearance-outline .mat-form-field-outline {
		color: #00306747;
	}
}

.high-light-outline {
	&.mat-form-field-appearance-outline .mat-form-field-outline {
		color: rgb(39 119 219) !important;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-start {
		border: 2px solid;
		border-right-style: none;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-gap {
		border: 2px solid;
		border-left-style: none;
		border-right-style: none;
	}

	&.mat-form-field-appearance-outline .mat-form-field-outline-end {
		border: 2px solid;
		border-left-style: none;
	}
}

/* increase space size in between form control */
.form-row > .col,
.form-row > [class*="col-"] {
	padding-left: 14px !important;
	padding-right: 14px !important;
	margin-bottom: 8px !important;
}

.form-row > .col,
.form-row > [class*="col-"] {
  &.remove-spacing {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.space-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

/* when form input is disabled */
.form-disabled {
	&.mat-form-field-appearance-outline .mat-form-field-outline {
		background: #f7f7f7 !important;
	}

	&.mat-input-element:disabled {
		color: black !important;
		background: #f7f7f7 !important;
	}
}

/* when date is disabled */
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after,
.mat-input-element:disabled {
	color: black !important;
	background: #f7f7f7 !important;
}

// new style
// ---------------------------------------------
// mat-form-field {
// 	font-size: 14px !important;
// }

// .mat-form-field-outline-gap {
// 	width: 0 !important;
// }

// .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
// 	transform: translateY(-1.7em) scale(1) !important;
// }

// mat-form-field mat-label {
// 	background-color: #f2f2f2;
// 	padding-right: 6px;
// 	padding-left: 6px;
// 	border-radius: 12px;
// }
