@import "./m_variables.scss";

.inv-status {
            text-align: center;
            margin-top: 10px !important;
            color: white !important;
            font-weight: normal !important;
            border-radius: 20px;
            min-width: 95px;
            // width: 112px;
            width: 100px;
            font-size: 12px !important;
            // padding: 0px 16px;
            padding: 0px 0px;

            &.white {
                        background-color: white !important;
                        color: #c5a600 !important;

                        // background-color: $main-color-light;
                        // color: white;
            }

            &.approve {
                        background-color: $approve !important;
            }

            &.inprogress {
                        background-color: $inprogress !important;
            }

            &.reject {
                        background-color: $reject !important;
            }

            &.return {
                        background-color: $return !important;
            }

            &.new {
                        background-color: $new !important;
            }

            &.assign {
                        background-color: $assign !important;
            }

            &.check {
                        background-color: $check !important;
            }
}
