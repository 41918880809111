@import "./m_variables.scss";

@mixin getRomanNumber($num) {
    &::before {
        content: $num ". ";
        font-family: "Times New Roman", Times, serif;
        font-size: 20px !important;
        font-weight: bold !important;
    }
}

.main-section-header {
    font-size: 16px !important;
    margin-top: 2px !important;
    margin-bottom: 32px !important;
    color: $main-color-light !important;
    font-weight: bold !important;

    &.sec-one {
        @include getRomanNumber("I");
    }

    &.sec-two {
        @include getRomanNumber("II");
    }

    &.sec-three {
        @include getRomanNumber("III");
    }

    &.sec-four {
        @include getRomanNumber("IV");
    }
}

div.section {
    margin-bottom: 0px;

    hr {
        width: 95%;
        // background: #f3f6f9;
        background: #87a9c51f;
        text-align: center;
        border: none;
        height: 1px;
    }

    div.section-header {
        margin: 15px auto;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        position: relative;

        p {
            font-weight: bold;
            margin-bottom: 10px;
            color: $main-color-light;
            padding-bottom: 5px;
            border-left: 8px solid $icon-color;
            padding-left: 16px;
            border-radius: 5px 5px;
            padding-top: 0px;
            padding-bottom: 0px;

            mat-icon {
                vertical-align: middle;
                margin-bottom: 6px;
                margin-right: 5px;

                display: none;
            }

            mat-icon.error-alert-icon {
                color: $reject;
                margin-left: 7px;
                margin-top: 4px;
                font-size: 22px;
            }
        }
        
        p.msg-container {
            font-size: small;
            color: red;
            font-weight: normal;
            border-left: unset;
            padding-left: 0;
        }

        button {
            margin-left: auto;
            margin-top: -10px;
        }
    }

    .msg-container {
        margin-left: auto;
    }

    div.row {
        margin: 10px 0px;
    }
}
