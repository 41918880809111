.mat-tab-header {
            border-bottom: 1px solid #e0e0e0 !important;
}

.normal {
            font-weight: normal !important;
}

.mat-tab-labels {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.mat-tab-label-content {
            mat-icon {
                        margin-right: 8px;
            }
}

.mat-tab-label {
            opacity: 1 !important;
            color: #a3a3a3 !important;
            font-size: 16px !important;
            font-family: "SegoeUI", "KhmerOSbattambang" !important;

            //     color: $main-main-color-dark;
}

.mat-ink-bar {
            background-color: $icon-color !important;
            // background-color: #0c538f !important;
}

.mat-tab-label-active {
            color: darken($color: $icon-color, $amount: 12%) !important;
}

.mat-tab-body-content {
            padding-top: 5px !important;
}

.tab-content {
            margin-top: 12px;
            overflow: hidden;
}
