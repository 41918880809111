
/*--- m_file scss ---*/
/*--- Styling on drag drop dialog when view file ---*/
/*--- Styling on display list row of files (id doc, supporting doc, etc..) ---*/

.file-viewer {
            z-index: 1;
            pointer-events: none !important;
}

.file-viewer.cdk-overlay-dark-backdrop {
            background: rgba(6, 39, 67, 0);
}

.custom-file-container .mat-dialog-container {
            resize: both;
            overflow: auto;
            background: #fff;
            max-width: 100% !important;
            padding-bottom: 0px;
}

.file-row {
            display: flex;
            align-items: center;

            .check {
                        color: green;
            }

            .error {
                        color: red;
            }

            .index {
                        margin-top: 12px;
                        margin-right: 20px;

                        &::after {
                                    content: ". ";
                        }
            }

            .file-container {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        border: 2px solid #f3f6f9;
                        background: white;
                        margin: 8px 0;
                        padding: 4px 8px 4px 16px;
                        border-radius: 10px;
                        transition: 0.1s;

                        &:hover {
                                    cursor: pointer;
                                    border: 2px solid $icon-color;
                        }
                        
                        &.has-index {
                            width: calc(100% - 28px);
                        }

                        .file-name {
                                    color: $main-color-light;
                                    font-size: $xs-font;
                                    font-weight: 400;
                                    margin: 12px 0;
                                    width: calc(100vw - 310px);
                                    min-width: 120px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    line-height: 22px;
                        }

                        .file-action {
                                    margin-left: auto;
                                    display: flex;

                                    .preview {
                                                color: #004281;

                                                mat-icon {
                                                    // margin-left: 8px;
                                                    font-size: $lg-font;
                                                }
                                    }

                                    .remove {
                                                color: #ff0000;

                                                mat-icon {
                                                    // margin-left: 8px;
                                                    font-size: $lg-font;
                                                }
                                    }

                                    .download {
                                                color: green;

                                                mat-icon {
                                                    // margin-left: 8px;
                                                    font-size: $lg-font;
                                                }
                                    }
                        }
            }

            .file-error {
                        border: 2px solid #d3181830;
            }

            @media screen and (max-width: 768px) {
                margin-right: 0;
            }
}
